/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "bizhelp-miami-web-20200402172703-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://bizhelp-miami-web-20200402172703-hostingbucket-prod.s3-website-us-east-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "us-east-1:247b64e6-35c4-43b2-b52e-73097f8accf4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Dsv1p1EKR",
    "aws_user_pools_web_client_id": "4oqh4389960h05cr22t322n2e3",
    "oauth": {},
    "aws_user_files_s3_bucket": "bizhelp-miami-web-20200402172703-hostingbucketweb-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "fundingAssistanceFormResponses-prod",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "bizhelpMiamiFundingFormResponses",
            "endpoint": "https://9uugo7say7.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "predictions": {
        "convert": {
            "translateText": {
                "region": "us-east-1",
                "proxy": false,
                "defaults": {
                    "sourceLanguage": "es",
                    "targetLanguage": "en"
                }
            }
        }
    }
};


export default awsmobile;
