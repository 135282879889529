/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import Amplify, {Storage, API, Interactions} from 'aws-amplify';
import Predictions, {AmazonAIPredictionsProvider} from '@aws-amplify/predictions'
import awsconfig from './src/aws-exports';
import "./src/styles/global.css";

Amplify.configure(awsconfig);
Interactions.configure({
  bots: {
    "bc_chatbot": {
      "name": "bc_chatbot",
      "alias": "$LATEST",
      "region": "us-east-1",
    },
  }
});
Predictions.configure();
Predictions.addPluggable(new AmazonAIPredictionsProvider())
Storage.configure();
API.configure();
