// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-additional-funding-foundations-grants-nonprofits-js": () => import("./../src/pages/additional-funding-foundations-grants-nonprofits.js" /* webpackChunkName: "component---src-pages-additional-funding-foundations-grants-nonprofits-js" */),
  "component---src-pages-additional-funding-foundations-js": () => import("./../src/pages/additional-funding-foundations.js" /* webpackChunkName: "component---src-pages-additional-funding-foundations-js" */),
  "component---src-pages-additional-funding-grants-js": () => import("./../src/pages/additional-funding-grants.js" /* webpackChunkName: "component---src-pages-additional-funding-grants-js" */),
  "component---src-pages-additional-funding-non-profit-js": () => import("./../src/pages/additional-funding-non-profit.js" /* webpackChunkName: "component---src-pages-additional-funding-non-profit-js" */),
  "component---src-pages-additional-funding-partners-js": () => import("./../src/pages/additional-funding-partners.js" /* webpackChunkName: "component---src-pages-additional-funding-partners-js" */),
  "component---src-pages-additional-funding-state-and-federal-js": () => import("./../src/pages/additional-funding-state-and-federal.js" /* webpackChunkName: "component---src-pages-additional-funding-state-and-federal-js" */),
  "component---src-pages-additional-funding-js": () => import("./../src/pages/additional-funding.js" /* webpackChunkName: "component---src-pages-additional-funding-js" */),
  "component---src-pages-business-assistance-js": () => import("./../src/pages/business-assistance.js" /* webpackChunkName: "component---src-pages-business-assistance-js" */),
  "component---src-pages-business-technical-assistance-js": () => import("./../src/pages/business-technical-assistance.js" /* webpackChunkName: "component---src-pages-business-technical-assistance-js" */),
  "component---src-pages-cares-act-and-other-loan-prep-js": () => import("./../src/pages/cares-act-and-other-loan-prep.js" /* webpackChunkName: "component---src-pages-cares-act-and-other-loan-prep-js" */),
  "component---src-pages-funding-js": () => import("./../src/pages/funding.js" /* webpackChunkName: "component---src-pages-funding-js" */),
  "component---src-pages-growth-marketing-js": () => import("./../src/pages/growth-marketing.js" /* webpackChunkName: "component---src-pages-growth-marketing-js" */),
  "component---src-pages-immigration-attorneys-js": () => import("./../src/pages/immigration-attorneys.js" /* webpackChunkName: "component---src-pages-immigration-attorneys-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individual-workforce-js": () => import("./../src/pages/individual-workforce.js" /* webpackChunkName: "component---src-pages-individual-workforce-js" */),
  "component---src-pages-international-business-and-non-resident-js": () => import("./../src/pages/international-business-and-non-resident.js" /* webpackChunkName: "component---src-pages-international-business-and-non-resident-js" */),
  "component---src-pages-international-business-js": () => import("./../src/pages/international-business.js" /* webpackChunkName: "component---src-pages-international-business-js" */),
  "component---src-pages-intl-biz-nr-js": () => import("./../src/pages/intl-biz-nr.js" /* webpackChunkName: "component---src-pages-intl-biz-nr-js" */),
  "component---src-pages-micro-bridge-funding-js": () => import("./../src/pages/micro-bridge-funding.js" /* webpackChunkName: "component---src-pages-micro-bridge-funding-js" */),
  "component---src-pages-new-client-banks-js": () => import("./../src/pages/new-client-banks.js" /* webpackChunkName: "component---src-pages-new-client-banks-js" */),
  "component---src-pages-ocog-js": () => import("./../src/pages/ocog.js" /* webpackChunkName: "component---src-pages-ocog-js" */),
  "component---src-pages-partners-js": () => import("./../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-ppp-access-js": () => import("./../src/pages/ppp-access.js" /* webpackChunkName: "component---src-pages-ppp-access-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-private-partners-js": () => import("./../src/pages/private-partners.js" /* webpackChunkName: "component---src-pages-private-partners-js" */),
  "component---src-pages-procurement-js": () => import("./../src/pages/procurement.js" /* webpackChunkName: "component---src-pages-procurement-js" */),
  "component---src-pages-program-overview-js": () => import("./../src/pages/program-overview.js" /* webpackChunkName: "component---src-pages-program-overview-js" */),
  "component---src-pages-resource-partners-axis-helps-js": () => import("./../src/pages/resource-partners-axisHelps.js" /* webpackChunkName: "component---src-pages-resource-partners-axis-helps-js" */),
  "component---src-pages-resource-partners-b-2-b-js": () => import("./../src/pages/resource-partners-b2b.js" /* webpackChunkName: "component---src-pages-resource-partners-b-2-b-js" */),
  "component---src-pages-resource-partners-other-js": () => import("./../src/pages/resource-partners-other.js" /* webpackChunkName: "component---src-pages-resource-partners-other-js" */),
  "component---src-pages-resource-partners-js": () => import("./../src/pages/resource-partners.js" /* webpackChunkName: "component---src-pages-resource-partners-js" */),
  "component---src-pages-resource-support-js": () => import("./../src/pages/resource-support.js" /* webpackChunkName: "component---src-pages-resource-support-js" */),
  "component---src-pages-sba-approved-lenders-js": () => import("./../src/pages/sba-approved-lenders.js" /* webpackChunkName: "component---src-pages-sba-approved-lenders-js" */),
  "component---src-pages-specialized-services-js": () => import("./../src/pages/specialized-services.js" /* webpackChunkName: "component---src-pages-specialized-services-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-support-contact-us-js": () => import("./../src/pages/support-contact-us.js" /* webpackChunkName: "component---src-pages-support-contact-us-js" */),
  "component---src-pages-technology-maximization-js": () => import("./../src/pages/technology-maximization.js" /* webpackChunkName: "component---src-pages-technology-maximization-js" */)
}

